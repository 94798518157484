body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  border-right: 1px solid #ececec;
}
.logo p {
  margin: 0;
  font-weight: 900;
  color: #4b8c4a;
}
.ant-table-placeholder {
  z-index: 10 !important;
}
.qr-reader {
  width: 100%;
  height: 100%;
  position: fixed !important;
  inset: 0;
  background-color: #00000060;
}
.content {
  margin: 24px 16px;
  padding: 24px;
}

@media only screen and (max-width: 600px) {
  .content {
    margin: 5px;
    padding: 0;
  }
}
